import logo from './resources/ns-logo.png';
import './App.scss';
import {getFirebaseConfig} from "./firebase-config";
import {initializeApp} from "firebase/firebase-app";
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import LoginForm from './components/login-form';
// import {getAnalytics} from "firebase/firebase-analytics";


function App() {
    // Initialize Firebase
    const app = initializeApp(getFirebaseConfig());
    // const analytics = getAnalytics(app);

    return (
        <Container>
            <header>
                <Row>
                    <Col className="d-flex justify-content-center py-3">
                        <img src={logo} className="app-logo" alt="Notorious Stories"/>
                    </Col>
                </Row>
            </header>
            <Row className="d-flex justify-content-center">
                <Col xs={12} md={6} lg={4} xxl={3}>
                    <LoginForm />
                </Col>
            </Row>
        </Container>
    );
}

export default App;
