import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, {useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import {initializeApp} from "firebase/firebase-app";
import {getFirebaseConfig} from "../firebase-config";

const LoginForm = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target),
            formDataObj = Object.fromEntries(formData.entries());
        setLoading(true);
        const app = initializeApp(getFirebaseConfig());
        console.log(app.name);
        const auth = getAuth();
        signInWithEmailAndPassword(auth, formDataObj.email, formDataObj.password)
            .then((userCredential) => {
                setLoading(false);
                // Signed in
                const user = userCredential.user;
                // ...
            })
            .catch((error) => {
                setLoading(false);
                const errorCode = error.code;
                const errorMessage = error.message;
                setError(`${errorCode} ${errorMessage}`);
            });
    }

    return (
        <Form onSubmit={handleSubmit}>
            {!!error && <Row>
                <Col>
                    <p className="alert-danger">{error}</p>
                </Col>
            </Row>}
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Username (email)</Form.Label>
                        <Form.Control type="email" placeholder="Enter username or email" name="email"/>
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" name="password"/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                {loading ?
                    <Col>
                        <p>...</p>
                    </Col>
                    :<Col>
                    <Button variant="primary" type="submit">
                        Login
                    </Button>
                </Col>}
                <Col className="d-inline-flex justify-content-end">
                    <Button variant="secondary">
                        Help
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default LoginForm;