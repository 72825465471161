const config = {
  apiKey: "AIzaSyCd-xLmUo1CW2CNCAdFvCAuFFLgPaujFxA",
  authDomain: "notorious-stories-cards.firebaseapp.com",
  projectId: "notorious-stories-cards",
  storageBucket: "notorious-stories-cards.appspot.com",
  messagingSenderId: "332396176582",
  appId: "1:332396176582:web:ad49537e54726abc6563c7",
  measurementId: "G-88K1FMMCZ2"
};

export function getFirebaseConfig() {
  if (!config || !config.apiKey) {
    throw new Error('No Firebase configuration object provided.' + '\n' +
    'Add your web app\'s configuration object to firebase-config.js');
  } else {
    return config;
  }
}